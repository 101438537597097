import React, { useState } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebaseConfig";

const ContactForm = () => {
  // State to manage form input values
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    mobile: "",
  });

  // State to manage form submission status
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [error, setError] = useState("");

  // Handle input changes
  const handleChange = (e) => {
    // console.log(e.target.value, e.target.name);
    const { name, value } = e.target;

    logFBEvent(name, { value: value });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  function logFBEvent(key, value) {
    logEvent(analytics, key, value);
  }

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    setIsSubmitting(true);

    try {
      const response = await fetch("https://api.ezlocus.com/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSubmitSuccess(true);
        setError("");
        // Clear the form after successful submission
        setFormData({
          name: "",
          email: "",
          message: "",
          mobile: "",
        });
      } else {
        throw new Error("Failed to submit form");
      }
    } catch (err) {
      setSubmitSuccess(false);
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="contact-us">
      <div
        className="container mt-5"
        style={{
          boxShadow: "6px 6px #673ab74d",
          border: "1px solid lightgray",
        }}
      >
        <div className="form-container">
          <h2 style={{marginLeft: "0%"}}>Get in Touch with Us</h2>
          <span className="text">
            We're here to answer your questions and help you get started. Fill
            out the form and we'll be in touch soon.
          </span>
          {/* Display success or error messages as banners */}
          {submitSuccess !== null && (
            <div
              className={`form-message ${submitSuccess ? "success" : "error"}`}
            >
              {submitSuccess
                ? "Form submitted successfully!"
                : `Error: ${error}`}
            </div>
          )}
          <form className="mt-4 w-100" onSubmit={handleSubmit}>
            <div className="row mb-3">
              <div className="col-md-6">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <input
                  type="tel"
                  name="mobile"
                  className="form-control"
                  placeholder="Mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <textarea
                  name="message"
                  className="form-control"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
            </div>
            <button type="submit" className="button mt-3 btns" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
            <p></p>
            <p></p>
            {/* {submitSuccess !== null && (
            <div className={`mt-2 ${submitSuccess ? 'text-success' : 'text-danger'}`}>
              {submitSuccess ? 'Form submitted successfully!' : `Error: ${error}`}
            </div>
          )} */}
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
