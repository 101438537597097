import React from "react";
import "../style/scss/_infoSection.scss";
import Coin from "../assets/svg/Coin.svg";

const items = [
  {
    id: 1,
    name: "Real-Time Availability",
    description: [
      "Track and manage parking spots in real-time to ensure optimal utilization.",
    ],
  },
  {
    id: 2,
    name: "Comprehensive Analytics",
    description: ["Gain insights into parking patterns and performance with detailed reports and analytics."],
  },
  {
    id: 1,
    name: "Control revenue leakage",
    description: ["Know exact number of vehicle parked in with time"],
  },
  {
    id: 2,
    name: "Complete ticketless solution",
    description: [
      "With ezLocus, enjoy ticket less parking.",
    ],
  },

  {
    id: 4,
    name: "Manage passes and allotted parking",
    description: [
      "Sell your passes online and manage alloted or owned parking",
    ],
  },
  
  {
    id: 6,
    name: "Enhanced Operational Insights",
    description: [
      "Make informed decisions, optimize space utilization, and drive strategic improvements based on data-driven insights.",
    ],
  },
];

const InfoSection = () => {
  return (
    <div className="info-section">
      <div className="container card-container">
        <div style={{ width: "100%" }}>
          <h1>One platform, many benefits</h1>
        </div>
        {items.map((item) => (
          <div className="info-card-section" key={item.id}>
            {/* <img className="info-icon" src={Coin} alt="" /> */}
            <div className="info-card">
              <p className="heading">{item.name}</p>
              <p className="description">{item.description}</p>
            </div>
          </div>
        ))}
        <div className="info-button" style={{ width: "100%" }}>
          <a href="/pricing" className="price-button">
            Check out pricing
          </a>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
