import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import "../style/scss/_get_start.scss";
import SuccessPage from "./success_page";

import HomeSVG from "../assets/svg/Home.svg";
import Card from "../assets/svg/card.svg";

const MultiStepForm = () => {
  const location = useLocation();
  const [SubsId, setSubsId] = useState("");
  const [subsplanamount, setSubsplanamount] = useState("");
  const [TokenResponse, setTokenResponse] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [otpPlaceholder, setOtpPlaceholder] = useState("OTP");
  const [successStatus, setSuccessStatus] = useState("none");
  const [failureStatus, setfailureStatus] = useState("none");
  const [userExist, setuserExist] = useState("");
  const [marchantId, setmarchantId] = useState("");
  const { duration, plan } = useParams();
  useEffect(() => {
    const subscriptionData = async () => {
      try {
        const SubData = await axios.get(
          "https://api.ezlocus.com/subscription/"
        );
        const filteredPlans = SubData.data.items.filter(
          (data) =>
            data.period === `${duration}`.toLowerCase() &&
            data.item.name === `${plan}`
        );
        const id = filteredPlans.map((item) => item.id).join("");
        const amount = filteredPlans.map((item) => item.item.amount).join("");
        setSubsId(id);
        setSubsplanamount(amount);
      } catch (error) {
        console.log(error);
      }
    };
    subscriptionData();
  }, []);
  const [formData, setFormData] = useState({
    mobile: "",
    otp: "",
    fullName: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const handleChange = async (e) => {
    const { name, value /*, type, files, checked */ } = e.target;
    const newFormData = { ...formData };
    newFormData[name] = value;
    setFormData(newFormData);
  };

  const handleOtpFocus = async () => {
    if (
      formData.fullName &&
      formData.email &&
      formData.mobile &&
      !TokenResponse
    ) {
      setOtpPlaceholder("OTP is being sent to your mobile number...");
      try {
        const registerApi = await axios.post(
          "https://api.ezlocus.com/merchant/register",
          {
            mobile: formData.mobile,
            user_type: "merchant",
            email: formData.email,
            name: formData.fullName,
          }
        );
        console.log(registerApi);

        setTokenResponse(registerApi.data.token);
        if (registerApi.data.status_code == 409) {
          setuserExist(
            <>
              Your subscription is active. Please login to your{" "}
              <a
                href="https://merchant.ezlocus.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{textDecoration:"none", color: "red"}}
              >
                Merchant portal
              </a>
              .
            </>
          );
          setIsDisabled(true);
        } /*else if (registerApi.data.status_code == 202) {
          setuserExist("Welcome to ezlocus");
        }*/
      } catch (error) {
        setErrors({
          otp: error.response.data.message,
        });
        setOtpPlaceholder("OTP");
      }
    }
  };

  const handleOtpBlur = () => {
    if (!formData.otp) {
      setOtpPlaceholder("OTP");
    }
  };

  const validateStep = () => {
    let formErrors = {};
    if (!formData.fullName) formErrors.fullName = "Full name is required";
    if (!formData.email) formErrors.email = "Email is required";
    if (!formData.mobile) formErrors.mobile = "Mobile number is required";
    if (!formData.otp) formErrors.otp = "OTP is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  useEffect(() => {
    setTokenResponse(null);
    setuserExist("");
    setErrors({});
    setIsDisabled(false);
  }, [formData.email, formData.mobile]);

  function getFutureDate30Days() {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + 30);
    return Math.floor(futureDate.getTime() / 1000);
  }

  function getCustomFutureDate(period) {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);

    if (period === "monthly") {
      futureDate.setDate(currentDate.getDate() + 60); // 30 days + 30 days
    } else if (period === "yearly") {
      futureDate.setDate(currentDate.getDate() + 395); // 30 days + 365 days
    } else {
      throw new Error("Invalid period. Use 'monthly' or 'yearly'.");
    }

    return Math.floor(futureDate.getTime() / 1000);
  }
  let marchatIds;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateStep()) {
      try {
        const response = await axios.post(
          "https://api.ezlocus.com/merchant/auth/verifyOtp",
          {
            mobile: formData.mobile,
            otp: formData.otp,
          },
          {
            headers: {
              Authorization: `Bearer ${TokenResponse}`,
            },
          }
        );
        setTokenResponse(response.data.token);
        setSuccessStatus("flex");

        marchatIds = response.data._id;

        if (response.data.token) {
          // Handle successful OTP verification (e.g., redirect to another page or show success message)
          try {
            const runSubscription = await axios.post(
              "https://api.ezlocus.com/subscription/",
              {
                plan_id: SubsId,
                total_count: 1,
                quantity: 1,
                customer_notify: 1,
                start_at: getFutureDate30Days(),
                expire_by: getCustomFutureDate(duration.toLowerCase()), /// this monthly and yearly should be coming dynamically
              },
              {
                headers: {
                  Authorization: `Bearer ${TokenResponse}`,
                },
              }
            );
            console.log("OTP verified successfully");
            // displayRazorpay(runSubscription.data.id);
          } catch (error) {
            console.log(error);
            setErrors({ status: "Subscription verification failed" });
          }
        } else {
          // Show OTP verification error
          setErrors({ otp: "OTP verification failed" });
        }
      } catch (error) {
        // Handle network or server error
        setErrors({ otp: "An error occurred during verification" });
      }
    }
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function paymentUpdate(payment_id, subscription_id, signature) {
    const marchantupdatePayment = await axios.post(
      "https://api.ezlocus.com/subscription/updatePayment",
      {
        merchant: marchatIds,
        price: `${subsplanamount / 100}.00`,
        subscription: subscription_id,
        payment_id: payment_id,
        rz_signature: signature,
      },
      {
        headers: {
          Authorization: `Bearer ${TokenResponse}`,
        },
      }
    );
    // updatePayment();
  }

  async function displayRazorpay(sId) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      window.location.href = "/error_page";
      return;
    }

    // creating a new order
    // const result = await axios.post("http://localhost:5000/payment/orders");

    // if (!result) {
    //     alert("Server error. Are you online?");
    //     return;
    // }

    // Getting the order details back
    // const { amount, id: order_id, currency } = result.data;

    var options = {
      key: "rzp_test_x13vuGnwov7Uf1",
      subscription_id: sId,
      name: "Locus",
      description: "Digital solution of your parking",
      image: "https://ezlocus.com/logo.png",
      handler: function (response) {
        console.log(response);
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_subscription_id);
        // alert(response.razorpay_signature);
        if (response.razorpay_payment_id) {
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_subscription_id);
          // alert(response.razorpay_signature);
          paymentUpdate(
            response.razorpay_payment_id,
            response.razorpay_subscription_id,
            response.razorpay_signature
          );
          setSuccessStatus("flex");
        } else {
          // Handle failure scenario
          alert("Payment failed. Please try again.");
          setfailureStatus("flex");
        }
      },
      prefill: {
        name: formData.fullName,
        email: formData.email,
        contact: formData.mobile,
      },
      notes: {
        note_key_1: "Tea. Earl Grey. Hot",
        note_key_2: "Make it so.",
      },
      theme: {
        color: "#673ab7",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const handlePayment = async () => {
    // const orderUrl = 'http://localhost:5000/create-order'; // Your backend endpoint
    // const paymentDetails = {
    //   amount: 500, // Amount in INR
    //   currency: 'INR',
    //   receipt: 'receipt#1',
    // };

    try {
      // const { data } = await axios.post(orderUrl, paymentDetails);
      // const options = {
      //   key: 'rzp_test_LtxClYzPo2ZqMN', // Enter the Key ID generated from the Dashboard
      //   amount: data.amount,
      //   currency: data.currency,
      //   name: 'Locus',
      //   description: 'Digital solution of your parking',
      //   image: 'https://ezlocus.com/logo.png',
      //   order_id: data.id,
      //   handler: function (response) {
      //     alert(`Payment Successful! Payment ID: ${response.razorpay_payment_id}`);
      //   },
      //   prefill: {
      //     name: 'Avinash',
      //     email: 'your.email@example.com',
      //     contact: '9999999999',
      //   },
      //   notes: {
      //     address: 'Razorpay Corporate Office',
      //   },
      //   theme: {
      //     color: '#F37254',
      //   },
      // };
      // const rzp1 = new window.Razorpay(options);
      // rzp1.open();
    } catch (error) {
      // console.error('Error creating order:', error);
    }

    var options = {
      key: "rzp_test_LtxClYzPo2ZqMN",
      subscription_id: "sub_OfAm6fw0K3c9LV",
      name: "Acme Corp.",
      description: "Monthly Test Plan",
      image: "/your_logo.png",
      handler: function (response) {
        alert(response.razorpay_payment_id);
        alert(response.razorpay_subscription_id);
        alert(response.razorpay_signature);
      },
      prefill: {
        name: "Gaurav Kumar",
        email: "gaurav.kumar@example.com",
        contact: "+919876543210",
      },
      notes: {
        note_key_1: "Tea. Earl Grey. Hot",
        note_key_2: "Make it so.",
      },
      theme: {
        color: "#F37254",
      },
    };
    //  var paymentObject = new Razorpay(options);
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  // const renderStepContent = (step) => {
  //   switch (step) {
  //     case 1:
  //       return (
  //         <>
  //           <input
  //             type="text"
  //             name="fullName"
  //             placeholder="Full Name"
  //             value={formData.fullName}
  //             onChange={handleChange}
  //           />
  //           <input
  //             type="email"
  //             name="email"
  //             placeholder="Email"
  //             value={formData.email}
  //             onChange={handleChange}
  //           />
  //           <input
  //             type="text"
  //             name="mobile"
  //             placeholder="Mobile"
  //             value={formData.mobile}
  //             onChange={handleChange}
  //           />
  //           <input
  //             type="text"
  //             name="otp"
  //             placeholder="OTP"
  //             value={formData.otp}
  //             onChange={handleChange}
  //           />
  //         </>
  //       );
  //     case 2:
  //       return (
  //         <>
  //           <input
  //             type="text"
  //             name="fullName"
  //             placeholder="Full Name"
  //             value={formData.fullName}
  //             onChange={handleChange}
  //           />
  //           <input
  //             type="email"
  //             name="email"
  //             placeholder="Email"
  //             value={formData.email}
  //             onChange={handleChange}
  //           />
  //           <input
  //             type="text"
  //             name="parkingName"
  //             placeholder="Parking Name"
  //             value={formData.parkingName}
  //             onChange={handleChange}
  //           />
  //           <input
  //             type="time"
  //             name="openTime"
  //             value={formData.openTime}
  //             onChange={handleChange}
  //           />
  //           <input
  //             type="time"
  //             name="closeTime"
  //             value={formData.closeTime}
  //             onChange={handleChange}
  //           />
  //         </>
  //       );
  //     case 3:
  //       return (
  //         <>
  //           <input
  //             type="text"
  //             name="carSpots"
  //             placeholder="Car Spots"
  //             value={formData.carSpots}
  //             onChange={handleChange}
  //           />
  //           <input
  //             type="text"
  //             name="bikeSpots"
  //             placeholder="Bike Spots"
  //             value={formData.bikeSpots}
  //             onChange={handleChange}
  //           />
  //           <input
  //             type="text"
  //             name="carHourlyPrice"
  //             placeholder="Car Hourly Price"
  //             value={formData.carHourlyPrice}
  //             onChange={handleChange}
  //           />
  //           <input
  //             type="text"
  //             name="bikeHourlyPrice"
  //             placeholder="Bike Hourly Price"
  //             value={formData.bikeHourlyPrice}
  //             onChange={handleChange}
  //           />
  //         </>
  //       );
  //     case 4:
  //       return (
  //         <input
  //           type="text"
  //           name="address"
  //           placeholder="Address"
  //           value={formData.address}
  //           onChange={handleChange}
  //         />
  //       );
  //     case 5:
  //       return (
  //         <>
  //           <label>
  //             <input
  //               type="checkbox"
  //               name="facilities"
  //               value="CCTV"
  //               onChange={handleChange}
  //             />{" "}
  //             CCTV
  //           </label>
  //           <label>
  //             <input
  //               type="checkbox"
  //               name="facilities"
  //               value="Security"
  //               onChange={handleChange}
  //             />{" "}
  //             Security
  //           </label>
  //           <label>
  //             <input
  //               type="checkbox"
  //               name="facilities"
  //               value="EV Charging"
  //               onChange={handleChange}
  //             />{" "}
  //             EV Charging
  //           </label>
  //           <label>
  //             <input
  //               type="checkbox"
  //               name="facilities"
  //               value="Covered Parking"
  //               onChange={handleChange}
  //             />{" "}
  //             Covered Parking
  //           </label>
  //           <input
  //             type="file"
  //             name="image"
  //             accept="image/jpeg, image/png"
  //             onChange={handleChange}
  //           />
  //           {errors.image && <span className="error">{errors.image}</span>}
  //         </>
  //       );
  //     default:
  //       return null;
  //   }
  // };

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
      ></link>
      <div
        className="multi-step-form"
        style={{
          display:
            successStatus === "none" && failureStatus === "none"
              ? "flex"
              : "none",
        }}
      >
        <div className="left-column">
          {/* <img
            src={HomeSVG} // replace with actual images
            alt={`Step `}
          /> */}
          <div>
            <h2>Why ezLocus</h2>
            <div className="div-border"></div>
            <p>
              <h3>Effortless Parking Management</h3>
              <p>
                {" "}
                Our user-friendly interface and advanced features make managing
                your parking facility a breeze. From real-time tracking to
                detailed reporting, ezLocus simplifies every aspect of parking
                management.
              </p>
            </p>
            <p>
              <h3>Increased Revenue</h3>
              <p>
                Maximize your parking revenue with our dynamic pricing and
                occupancy optimization tools. Our system ensures you make the
                most out of every parking spot.
              </p>
            </p>
            <p>
              <h3>Comprehensive Analytics</h3>
              <p>
                Gain valuable insights into your parking operations with our
                detailed reports and analytics. Make data-driven decisions to
                improve efficiency and profitability.
              </p>
            </p>
            <p>
              <h3>Scalable Solutions</h3>
              <p>
                Whether you manage a small parking lot or a multi-level
                facility, ezLocus scales with your business needs. Our flexible
                solutions adapt to your unique requirements.
              </p>
            </p>
          </div>
        </div>
        <div className="right-column">
          <h2>Register to ezLocus</h2>
          
          <form onSubmit={handleSubmit}>
            <div className="form-step">
              <>
                <input
                  className="form-control"
                  type="text"
                  name="fullName"
                  placeholder="Full Name"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <input
                  className="form-control"
                  type="tel"
                  name="mobile"
                  placeholder="Mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  maxLength={10}
                  required
                />
                <input
                  className="form-control"
                  type="text"
                  name="otp"
                  placeholder={otpPlaceholder}
                  value={formData.otp}
                  onFocus={handleOtpFocus}
                  onChange={handleChange}
                  onBlur={handleOtpBlur}
                  required
                />
                {userExist && (
                  <p
                    style={{
                      color: "green",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {userExist}
                  </p>
                )}
                {errors.otp && <p style={{ color: "red" }}>{errors.otp}</p>}
                {errors.status && (
                  <p style={{ color: "red" }}>{errors.status}</p>
                )}
                <div className="agreeTerm">
                  <input type="checkbox" required />{" "}
                  <div>
                    <p>
                      I have read and agreed to the{" "}
                      <a href="/terms-and-conditions">terms and conditions</a>
                    </p>
                  </div>
                </div>
              </>
            </div>
            <div className="navigation-buttons">
              <button
                type="submit"
                className={`btn btn-submit ${
                  isDisabled ? "disabled bg-secondary border-secondary" : ""
                }`}
                disabled={isDisabled}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="success-section" style={{ display: successStatus }}>
        <SuccessPage />
        {/* <div className="left-column">
          <img
            src={HomeSVG} // replace with actual images
            alt={`Step `}
          />
        </div>*/}
        {/* <div className="right-column">
          <div class="success-container">
            <div className="success-div">
              <div class="success-icon">
                <img src={Card} alt="Payment Successful" />
              </div>
            </div>
            <h1>Subscription Successful!</h1>
            <p>Thank you for choosing ezLocus to manage your parking solutions. Your payment has been successfully processed, and your subscription is now active.</p>
            <p>
            You can now access the Merchant Portal to customize and complete your profile. This will help you optimize your parking operations and make the most of ezLocus’s powerful features.
              <br />
              or click here to return to the home page
            </p>
            <button class="home-button">Home</button>
          </div>
        </div> */}
      </div>
      <div className="failure-section" style={{ display: failureStatus }}>
        <div className="left-column">
          <img
            src={HomeSVG} // replace with actual images
            alt={`Step `}
          />
        </div>
        <div className="right-column">
          <div class="payment-failed-container">
            <div className="failure-div">
              <div class="failure-icon">
                <span>X</span>
              </div>
            </div>
            <h1>Payment Failed</h1>
            <p>
              Your payment was not successfully processed. Please contact our
              customer support.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiStepForm;

/*
api.ezlocus.com/merchant/register
{
    "mobile":"8866004488",
    "user_type":"merchant",
    "email":"abc@abc.co",
"name":"merchant name"
}
  */
/*
 api.ezlocus.com/merchant/auth/
{
    "mobile": 9540621919,
    "user_type":"merchant",
    "long":23.45,
    "lat":1.5
}
api.ezlocus.com/merchant/auth/verifyOtp
{
    "mobile":"8866004488",
    "otp":"123456"
}
 */
