import "../style/scss/_pricing.scss";
import Seo from "../seo";
import HomeSVG from "../assets/svg/Home.svg";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

const Pricing = () => {
  const navigate = useNavigate();
  const [billingCycle, setBillingCycle] = useState("Monthly");
  const [pricingData, setPricingData] = useState([]);
  useEffect(() => {
    // Fetch pricing data from the API
    const fetchPricingData = async () => {
      try {
        const response = await axios.get(
          "https://api.ezlocus.com/subscription/"
        );
        setPricingData(response.data.items);
      } catch (error) {
        console.error("Error fetching pricing data:", error);
      }
    };

    fetchPricingData();
  }, []);

  let monthly = pricingData.filter((item) => item.period === "monthly");
  let yearly = pricingData.filter((item) => item.period === "yearly");

  const handleClick = (planType) => {
    let duration = billingCycle;
    let plan = planType;
    navigate(`/get_start/${duration}/${plan}`);
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  // async function displayRazorpay() {
  //   const res = await loadScript(
  //     "https://checkout.razorpay.com/v1/checkout.js"
  //   );

  //   if (!res) {
  //     alert("Razorpay SDK failed to load. Are you online?");
  //     return;
  //   }

  //   // creating a new order
  //   // const result = await axios.post("http://localhost:5000/payment/orders");

  //   // if (!result) {
  //   //     alert("Server error. Are you online?");
  //   //     return;
  //   // }

  //   // Getting the order details back
  //   // const { amount, id: order_id, currency } = result.data;

  //   var options = {
  //     key: "rzp_test_LtxClYzPo2ZqMN",
  //     subscription_id: "sub_Ofeao5xcYoYkBQ",
  //     name: "Locus",
  //     description: "Digital solution of your parking",
  //     image: "https://ezlocus.com/logo.png",
  //     handler: function (response) {
  //       console.log(response);
  //       alert(response.razorpay_payment_id);
  //       alert(response.razorpay_subscription_id);
  //       alert(response.razorpay_signature);
  //     },
  //     prefill: {
  //       name: "Gaurav Kumar",
  //       email: "gaurav.kumar@example.com",
  //       contact: "+919876543210",
  //     },
  //     notes: {
  //       note_key_1: "Tea. Earl Grey. Hot",
  //       note_key_2: "Make it so.",
  //     },
  //     theme: {
  //       color: "#673ab7",
  //     },
  //   };

  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // }

  // const handlePayment = async () => {
  //   // const orderUrl = 'http://localhost:5000/create-order'; // Your backend endpoint
  //   // const paymentDetails = {
  //   //   amount: 500, // Amount in INR
  //   //   currency: 'INR',
  //   //   receipt: 'receipt#1',
  //   // };

  //   try {
  //     // const { data } = await axios.post(orderUrl, paymentDetails);
  //     // const options = {
  //     //   key: 'rzp_test_LtxClYzPo2ZqMN', // Enter the Key ID generated from the Dashboard
  //     //   amount: data.amount,
  //     //   currency: data.currency,
  //     //   name: 'Locus',
  //     //   description: 'Digital solution of your parking',
  //     //   image: 'https://ezlocus.com/logo.png',
  //     //   order_id: data.id,
  //     //   handler: function (response) {
  //     //     alert(`Payment Successful! Payment ID: ${response.razorpay_payment_id}`);
  //     //   },
  //     //   prefill: {
  //     //     name: 'Avinash',
  //     //     email: 'your.email@example.com',
  //     //     contact: '9999999999',
  //     //   },
  //     //   notes: {
  //     //     address: 'Razorpay Corporate Office',
  //     //   },
  //     //   theme: {
  //     //     color: '#F37254',
  //     //   },
  //     // };
  //     // const rzp1 = new window.Razorpay(options);
  //     // rzp1.open();
  //   } catch (error) {
  //     // console.error('Error creating order:', error);
  //   }

  //   var options = {
  //     key: "rzp_test_LtxClYzPo2ZqMN",
  //     subscription_id: "sub_OfAm6fw0K3c9LV",
  //     name: "Acme Corp.",
  //     description: "Monthly Test Plan",
  //     image: "/your_logo.png",
  //     handler: function (response) {
  //       alert(response.razorpay_payment_id);
  //       alert(response.razorpay_subscription_id);
  //       alert(response.razorpay_signature);
  //     },
  //     prefill: {
  //       name: "Gaurav Kumar",
  //       email: "gaurav.kumar@example.com",
  //       contact: "+919876543210",
  //     },
  //     notes: {
  //       note_key_1: "Tea. Earl Grey. Hot",
  //       note_key_2: "Make it so.",
  //     },
  //     theme: {
  //       color: "#F37254",
  //     },
  //   };
  //   //  var paymentObject = new Razorpay(options);
  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // };

  return (
    <>
      <Seo
        title="Pricing | ezLocus"
        description="Welcome to ezLocus, your ultimate solution for hassle-free parking management. At ezLocus, we believe in simplifying the parking experience for both businesses and users. Our innovative platform is designed to maximize efficiency, improve customer experience, and empower businesses to make informed decisions."
        keywords="Pricing, About Parking Booking, User Agreement, ezLocus Terms, Service Use, Payment, Privacy Policy"
        image="%PUBLIC_URL%/images/logo.png"
        url="https://ezlocus.com/pricing"
      />
      <section id="pricing" className="pricing-section">
        <div className="container">
          <div className="heading-section">
            <h1>Choose the Right Plan for Your Business</h1>
            <p className="pricing-detail-head">
              At ezLocus, we offer flexible subscription plans designed to meet
              the needs of businesses of all sizes.{" "}
            </p>
            <p className="pricing-detail-head">
              Whether you manage a single parking lot or multiple complex
              facilities, we have a plan that fits your requirements.
            </p>
          </div>
          <div className="billing-cycle-toggle">
            <button
              className={billingCycle === "Monthly" ? "active" : ""}
              onClick={() => setBillingCycle("Monthly")}
            >
              Monthly
            </button>
            <button
              className={billingCycle === "Yearly" ? "active" : ""}
              onClick={() => setBillingCycle("Yearly")}
            >
              Yearly
            </button>
          </div>

          <div className="pricing-cards">
            <div className="card">
              <div className="plan-header">
                <h3>Basic</h3>
                <p className="price">
                  <span className="price-amount">
                    {/* <span className='price-amount-strike'>{billingCycle === 'monthly' ? '₹4999' : '₹49999'}</span>  */}
                    <span className="price">
                      {billingCycle === "Monthly"
                        ? monthly.map((plan, index) =>
                            plan.item.name === "Basic" ? (
                              <span key={index} className="price-amount">
                                ₹{plan.item.amount / 100}
                              </span>
                            ) : null
                          )
                        : yearly.map((plan, index) =>
                            plan.item.name === "Basic" &&
                            plan.item.amount !== 399900 ? (
                              <span key={index} className="price-amount">
                                ₹{plan.item.amount / 100}
                              </span>
                            ) : null
                          )}
                      / {billingCycle}
                      {/* <span
                        className={
                          billingCycle === "Monthly" ? "" : "popular-tag"
                        }
                      > */}
                      {/* {billingCycle === "Monthly" ? "" : "save 20%"} */}
                      {/* </span> */}
                    </span>
                  </span>
                </p>
              </div>
              <button
                className="btn"
                onClick={/*displayRazorpay*/ () => handleClick("Basic")}
              >
                Get Started
              </button>

              <span
                className="price"
                style={{
                  fontSize: "11px",
                  textAlign: "start",
                  fontWeight: "600",
                  color: "#999999",
                  paddingTop: "8px",
                }}
              >
                No credit cards required
              </span>

              <hr className="divider" />
              <ul className="features">
                <li>
                  <i className="check-icon">&#10003;</i> <b> 1 Months free</b>
                </li>
                <li>
                  <i className="check-icon">&#10003;</i> 1 managed parking
                  location
                </li>
                <li>
                  <i className="check-icon">&#10003;</i> Up to 100 managed spots
                </li>
                <li>
                  <i className="check-icon">&#10003;</i> Admin panel dashboard
                </li>
                <li>
                  <i className="check-icon">&#10003;</i> Download parking report
                </li>
                <li>
                  <i className="check-icon">&#10003;</i> Operator app
                </li>
                <li>
                  <i className="check-icon">&#10003;</i> Manage alloted parking
                </li>
                {/* <li><i className="check-icon">&#10003;</i> Community Access</li> */}
              </ul>
            </div>
            <div className="card">
              <div className="plan-header">
                <h3>
                  Premium <span className="popular-tag">Popular</span>
                </h3>
                <p className="price">
                  {billingCycle === "Monthly"
                    ? monthly.map((plan, index) =>
                        plan.item.name === "Premium" ? (
                          <span key={index} className="price-amount">
                            ₹{plan.item.amount / 100}
                          </span>
                        ) : null
                      )
                    : yearly.map((plan, index) =>
                        plan.item.name === "Premium" ? (
                          <span key={index} className="price-amount">
                            ₹{plan.item.amount / 100}
                          </span>
                        ) : null
                      )}
                  {/* <span className="price-amount">
                    {billingCycle === "Monthly" ? "₹9999" : "₹99999"}
                  </span> */}
                  / {billingCycle}
                  <span className="nextLine"></span>
                  {/* <span
                    className={billingCycle === "Monthly" ? "" : "popular-tag"}
                  > */}
                  {/* {billingCycle === "Monthly" ? "" : "save 20%"} */}
                  {/* </span> */}
                </p>
              </div>
              <button className="btn" onClick={() => handleClick("Premium")}>
                Get Started
              </button>
              <span
                className="price"
                style={{
                  fontSize: "11px",
                  textAlign: "start",
                  fontWeight: "600",
                  color: "#999999",
                  paddingTop: "8px",
                }}
              >
                No credit cards required
              </span>
              <hr className="divider premium-divider" />
              <ul className="features">
                <li>
                  <i className="check-icon">&#10003;</i> All Free Features
                </li>
                <li>
                  <i className="check-icon">&#10003;</i> 5 managed parking
                  location
                </li>
                <li>
                  <i className="check-icon">&#10003;</i> Up to 500 managed spots
                </li>
                {/* <li>
                  <i className="check-icon">&#10003;</i> Manage multilevel
                  parking
                </li> */}
                <li>
                  <i className="check-icon">&#10003;</i> Sell parking permit{" "}
                </li>
                <li>
                  <i className="check-icon">&#10003;</i> Manage parking price
                </li>
                <li>
                  <i className="check-icon">&#10003;</i> Priority Support
                </li>
              </ul>
            </div>
            <div className="card">
              <div className="plan-header">
                <h3>Enterprise</h3>
                <p className="price">
                  <span className="price-amount">
                    {billingCycle === "monthly" ? " " : " "}
                  </span>
                </p>
              </div>
              <button className="btn" onClick={() => navigate("/contact-us")}>
                Contact Us
              </button>
              <span
                className="price"
                style={{
                  fontSize: "11px",
                  textAlign: "start",
                  fontWeight: "600",
                  color: "#999999",
                  paddingTop: "8px",
                }}
              >
                No credit cards required
              </span>
              <hr className="divider" />
              <ul className="features">
                <li>
                  <i className="check-icon">&#10003;</i> All Premium Features
                </li>
                <li>
                  <i className="check-icon">&#10003;</i> Custom Solutions
                </li>
                <li>
                  <i className="check-icon">&#10003;</i> Dedicated Account
                  Manager
                </li>
                {/* <li><i className="check-icon">&#10003;</i> Unlimited Parking</li> */}
              </ul>
            </div>
          </div>

          {/* why ezLocus */}
          {/* <h1>Why Choose ezLocus?</h1>
          <p className="priceing-detail">
            <span>Scalable Solutions</span>: Whether you are a small business or
            a large enterprise, our plans are designed to scale with your needs.
          </p>
          <p className="priceing-detail">
            <span>Real-Time Management</span>: Stay updated with real-time
            tracking and ensure optimal utilization of your parking spaces.{" "}
          </p>
          <p className="priceing-detail">
            <span>Comprehensive Analytics</span>: Make informed decisions with
            detailed reports and insights.{" "}
          </p>
          <p className="priceing-detail">
            <span>Exceptional Support</span>: Our dedicated support team is here
            to assist you every step of the way.
          </p> */}

          {/* start your free trial */}
          <h2>Start Your Free Trial</h2>
          <p className="priceing-detail">
            Not sure which plan to choose? Start with our 30-day free trial and
            experience the benefits of ezLocus without any commitment.{" "}
          </p>
          <p className="priceing-detail">
            Sign up now and see how our innovative parking management solution
            can transform your business.
          </p>

          {/* Have a question */}
          <h2>Have Questions?</h2>
          <p className="priceing-detail">
            If you need help choosing the right plan or have any questions, feel
            free to <a href="/contact-us">contact us</a>. Our team is ready to
            assist you.
          </p>
        </div>
      </section>
    </>
  );
};
export default Pricing;
