import Seo from "../seo";
const Tnc = () => {
  return (
    <>
      <Seo
        title="Terms and Conditions | ezLocus"
        description="Read the terms and conditions for using the ezLocus parking booking application. Learn about user responsibilities, service use, payment, and more."
        keywords="Terms and Conditions, Parking Booking Terms, User Agreement, ezLocus Terms, Service Use, Payment, Privacy Policy"
        image="%PUBLIC_URL%/images/logo.png"
        url="https://ezlocus.com/terms-and-conditions"
      />
      <section id="about-us" className="about-us-section">
        <div className="container">
          <p id="terms-and-conditions"><strong>Terms and Conditions</strong></p>
          <p id="introduction"><strong>Introduction</strong></p>
          <p>
            Welcome to ezLocus, a parking solution provided by <strong> i-cube Systems Pvt Ltd</strong>. These terms and conditions outline the rules and
            regulations for using our services. By accessing or using ezLocus,
            you agree to comply with and be bound by these terms. If you do not
            agree with any part of the terms, you may not use our services.
          </p>
          <p id="1-definitions"><strong>1. Definitions</strong></p>
          <ul>
            <li>
              <strong>&quot;User&quot;</strong> refers to any individual who
              accesses or uses ezLocus services.
            </li>
            <li>
              <strong>&quot;Service&quot;</strong> includes all services
              provided by ezLocus, including the mobile application and website.
            </li>
          </ul>
          <p id="2-user-responsibilities"><strong>2. User Responsibilities</strong></p>
          <ul>
            <li>
              Users must provide accurate and current information when
              registering for our services.
            </li>
            <li>
              Users are responsible for maintaining the confidentiality of their
              account information.
            </li>
            <li>
              Users agree not to use the service for any unlawful or prohibited
              activities.
            </li>
          </ul>
          <p id="3-service-use"><strong>3. Service Use</strong></p>
          <ul>
            <li>Users can reserve parking spaces via the ezLocus app.</li>
            <li>
              Reservation confirmations are subject to availability and will be
              communicated via email or SMS.
            </li>
            <li>
              Users must comply with all parking regulations and terms specified
              at the parking locations.
            </li>
          </ul>
          <p id="4-payment"><strong>4. Payment</strong></p>
          <ul>
            <li>
              Payments for services are processed through a secure payment
              gateway.
            </li>
            <li>
              ezLocus does not store any card details on its servers. Payment
              information is encrypted and processed according to the Payment
              Card Industry Data Security Standard (PCI-DSS).
            </li>
            <li>
              Transaction data is retained only as long as necessary to complete
              the transaction and is not stored thereafter.
            </li>
          </ul>
          <p id="5-cancellations-and-refunds"><strong>5. Cancellations and Refunds</strong></p>
          <ul>
            <li>
              Users can not cancel their reservations.
            </li>
            <li>
              Refund policies are subject to the terms specified at the time of
              reservation. Refunds will be processed to the original payment
              method.
            </li>
          </ul>
          <p id="6-liability"><strong>6. Liability</strong></p>
          <ul>
            <li>
              ezLocus is not responsible for any damages or loss of property at
              the parking locations.
            </li>
            <li>
              Users park at their own risk. ezLocus does not guarantee the
              security of vehicles parked at the reserved locations.
            </li>
          </ul>
          <p id="7-privacy-policy"><strong>7. Privacy Policy</strong></p>
          <p>
            ezLocus is dedicated to protecting your privacy. Any personal
            information you provide to us during your use of the Service will be
            used in accordance with our Privacy Policy. Our Privacy Policy forms
            a part of these Terms.
          </p>
          <p id="8-third-party-content"><strong>8. Third-Party Content</strong></p>
          <p>
            ezLocus may link to third-party websites, apps, or services, but
            does not endorse or take responsibility for their content, products,
            or services. Users should exercise caution and address any concerns
            to the respective site administrators.
          </p>
          <p id="9-termination"><strong>9. Termination</strong></p>
          <ul>
            <li>
              ezLocus reserves the right to suspend or terminate access to
              services for any user who violates these terms and conditions.
            </li>
            <li>
              Users may also terminate their account at any time by contacting
              customer support.
            </li>
          </ul>
          <p id="10-modifications-to-terms"><strong>10. Modifications to Terms</strong></p>
          <ul>
            <li>
              we reserves the right to modify these terms and conditions at
              any time. Users will be notified of any changes via email or
              through our app or website.
            </li>
            <li>
              Continued use of the service after any such changes constitutes
              acceptance of the new terms.
            </li>
          </ul>
          <p id="11-governing-law"><strong>11. Governing Law</strong></p>
          <ul>
            <li>
              These terms and conditions are governed by and construed in
              accordance with the laws of India.
            </li>
            <li>
              Any disputes arising out of or in connection with these terms
              shall be subject to the exclusive jurisdiction of the courts in
              India.
            </li>
          </ul>
          <p id="12-intellectual-property"><strong>12. Intellectual Property</strong></p>
          <p>
            All content on the ezLocus app and website, including text,
            graphics, logos, images, audio clips, and software, is owned by
             <strong> i-cube Systems Pvt Ltd</strong> and is protected by copyright, trademark, and other
            intellectual property laws. Unauthorised use of these trademarks is
            prohibited and may violate trademark laws.
          </p>
          <p id="13-limitation-liability"><strong>13. Limitation of Liability</strong></p>
          <p>
          <strong>i-cube Systems Pvt Ltd</strong> is not liable for any indirect, incidental, or consequential damages arising from your use of the Service. Our liability is limited to the maximum extent permitted by law.
          </p>
          <p id="14-legal-disclosures"><strong>14. Legal Disclosures</strong></p>
          <p>
            ezLocus reserves the right to disclose your personal data as
            required by applicable law, in response to lawful requests from
            public authorities, including to meet national security or law
            enforcement requirements, and when such disclosure is necessary to
            protect our rights or to comply with a judicial proceeding, court
            order, or other legal process served on us.
          </p>
          <p id="15-remedies-for-violations"><strong>15. Remedies for Violations</strong></p>
          <p>
            ezLocus reserves the right to seek all remedies available at law and
            in equity for violations of these Terms.
          </p>
          <p id="16-uptime"><strong>16. Uptime</strong></p>
          <p>
            ezLocus does not guarantee continuous availability of its app, API,
            content, or database, and may conduct maintenance without prior
            notice.
          </p>
          <p id="17-contact-us"><strong>17. Contact Us</strong></p>
          <p>
            For any questions or concerns about these terms and conditions,
            please contact us at{" "}
            <a href="mailto:support@ezLocus.com">support@ezLocus.com</a>.
          </p>
        </div>
      </section>
    </>
  );
};
export default Tnc;
