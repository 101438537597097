import React, { useState } from "react";
import axios from "axios";

function VerifyAccount() {
  const [formData, setFormData] = useState({ mobile: "", otp: "" });
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpAuthToken, setOtpAuthToken] = useState(null); // Store auth token from send OTP API
  const [authToken, setAuthToken] = useState(null); // Store auth token from verify OTP API
  const [userId, setUserId] = useState(null);

  const validateMobile = (mobile) => /^[0-9]{10}$/.test(mobile);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "mobile" && validateMobile(value)) {
      try {
        const response = await axios.post("https://api.ezlocus.com/auth", { mobile: value });
        if (response.data.token) {
          setIsOtpSent(true);
          setOtpAuthToken(response.data.token); // Save the auth token received
          setSubmitSuccess(true);
          setError(null);
        } else {
          throw new Error("Failed to send OTP. Please try again.");
        }
      } catch (error) {
        setIsOtpSent(false);
        setSubmitSuccess(false);
        setError(error.message);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitSuccess(null);
    setError(null);

    if (!isOtpSent) {
      setError("Please enter a valid mobile number to receive OTP.");
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await axios.post(
        "https://api.ezlocus.com/auth/verifyOtp",
        { ...formData },
        {
          headers: {
            Authorization: `Bearer ${otpAuthToken}`, // Use the auth token received from send OTP API
          },
        }
      );
      if (response.data.token) {
        setAuthToken(response.data.token);
        setUserId(response.data._id);
        setSubmitSuccess(true);
        setError(null);

        if (window.confirm("All your data associated with ezLocus will be deleted. Are you sure?")) {
          await handleAccountDeletion(response.data.token, response.data._id);
        }
      } else {
        throw new Error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      setSubmitSuccess(false);
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAccountDeletion = async (authToken, userId) => {
    try {
      const response = await axios.delete(`https://api.ezlocus.com/user/account/${userId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`, // Use the auth token received from verify OTP API
        },
        data: { userId },
      });
      if (response.data._id === userId) {
        alert("Account deleted successfully.");
      } else {
        throw new Error("Failed to delete account. Please try again.");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <section id="contact-us">
      <div className="container mt-5 border">
        <div className="form-container">
          <h2>Please verify account</h2>
          <span className="text">
            All your data associated with ezLocus will be deleted. We are committed to serving you better.
          </span>
          {submitSuccess !== null && (
            <div
              className={`form-message ${submitSuccess ? "success" : "error"}`}
            >
              {submitSuccess
                ? "OTP sent successfully!"
                : `Error: ${error}`}
            </div>
          )}
          <form className="mt-4 w-100" onSubmit={handleSubmit}>
            <div className="row mb-3">
              <div className="col-md-6">
                <input
                  type="tel"
                  name="mobile"
                  className="form-control"
                  placeholder="Mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="tel"
                  name="otp"
                  className="form-control"
                  placeholder="OTP"
                  value={formData.otp}
                  onChange={(e) =>
                    setFormData({ ...formData, otp: e.target.value })
                  }
                  required
                  disabled={!isOtpSent}
                />
              </div>
            </div>
            <button type="submit" className="btns mt-3" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default VerifyAccount;
