import React from 'react';
import KeyBenefitCard from "./KeyBenefitCard";
import Report from '../assets/svg/ez_report.svg';
import ParkPattern from '../assets/svg/ez_park_pattern.svg';
import RealTime from '../assets/svg/ez_real_time.svg';
import '../style/scss/_keybenefit.scss'; // Ensure the correct path

const KeyBenefit = () => {
  return (
    <div className="features-card-holder">
      <div className="card-container">
        <KeyBenefitCard
          imageUrl={RealTime}
          description="Real-time tracking of available spots and parking data"
          key={1}
          className="key-benefit-card"
        />
        <KeyBenefitCard
          imageUrl={Report}
          description="Detailed reports and insights to inform decision-making."
          key={2}
          className="key-benefit-card"
        />
        <KeyBenefitCard
          imageUrl={ParkPattern}
          description="Understand parking patterns and customer preferences."
          key={3}
          className="key-benefit-card"
        />
      </div>
    </div>
  );
};

export default KeyBenefit;
