import React, { useState } from "react";
import "../style/scss/_instructionCard.scss";

function InstructionCard({ step, title, content }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`instruction ${isHovered ? "hovered" : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="step-number">{step}</div>
      <div className="instruction-title">{title}</div>
      <div className="instruction-content">
        <img
          className="preview img-fluid"
          src={content}
          placeholder="blur"
          alt="Team"
        />
      </div>
    </div>
  );
}

export default InstructionCard;
