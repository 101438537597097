import '../style/scss/_about_us.scss'; // Import your SCSS file
import Seo from '../seo';
import HomeSVG from "../assets/svg/Story4.svg";
import { useNavigate } from "react-router-dom";


const AboutUs = () =>{
  const navigate = useNavigate();
    return <>
    <Seo
          title="About Us | ezLocus"
          description="Welcome to ezLocus, your ultimate solution for hassle-free parking management. At ezLocus, we believe in simplifying the parking experience for both businesses and users. Our innovative platform is designed to maximize efficiency, improve customer experience, and empower businesses to make informed decisions."
          keywords="About Us, About Parking Booking, User Agreement, ezLocus Terms, Service Use, Payment, Privacy Policy"
          image="%PUBLIC_URL%/images/logo.png"
          url="https://ezlocus.com/about-us"
      />
<section id="about-us" className="about-us-section">
<div class="page-container">
  <div class="first-row">
    <div class="text-column">
    <h2 className='about-title custom-text-color'>About Us</h2>
    <div className="div-border"></div>

    <h1 className="home-title custom-text-color">
    At ezLocus, we empower businesses with cloud based parking solutions that optimize space utilization, reduce congestion, and enhance operational efficiency. Our cloud-based platform simplifies the complexities of parking management, delivering real-time availability, seamless reservations, and actionable insights. Whether you're managing a single location or multiple sites, ezLocus adapts to your needs, helping you unlock new revenue streams and improve customer satisfaction.
              </h1>
              <div className='home-subtitle custom-text-color'>
              We're not just about parking; we're about creating smarter, more efficient urban spaces.
              </div>
              <p></p>
              <p className='home-title custom-subtext-color'>Partner with us to transform your parking operations and elevate your business.</p>
              <p></p>
              <div  >
          <button
            aria-hidden="true"
            onClick={() => navigate("/contact-us")}
            className="button mt-3 btns"
          >
            Request a Demo
          </button>
        </div>
              <p></p>

    </div>
    <div class="image-column">
      <img src={HomeSVG} alt="Your Image Description" />
    </div>
  </div>

  {/* <div class="second-row">
    <div class="row">
      <div class="bordered-container">
        <div class="content">
          <p>Content for the first container...</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="bordered-container">
        <div class="content">
          <p>Content for the second container...</p>
        </div>
      </div>
    </div>
  </div> */}
</div>
</section>



</>
}
export default AboutUs;
