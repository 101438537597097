import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ({ title, description, keywords, image, url, type = 'website' }) => {
    return (
        <Helmet>
            <title>{title || 'ezLocus'}</title>
            <meta name="description" content={description || 'ezLocus'} />
            <meta name="keywords" content={keywords || 'default, keywords'} />

            {/* Open Graph Meta Tags */}
            <meta property="og:title" content={title || 'ezLocus'} />
            <meta property="og:description" content={description || 'ezLocus'} />
            <meta property="og:image" content={image || '%PUBLIC_URL%/logo.png'} />
            <meta property="og:url" content={url || window.location.href} />
            <meta property="og:type" content={type} />

            {/* Twitter Card Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title || 'ezLocus'} />
            <meta name="twitter:description" content={description || 'ezLocus'} />
            <meta name="twitter:image" content={image || '%PUBLIC_URL%/logo.png'} />
            <link rel="canonical" href="https://ezlocus.com"></link>
        </Helmet>
    );
};

export default Seo;
