import React from "react";
import InstructionCard from "./InstructionCard.jsx";
import InstructionSlide from "./InstructionSlide.jsx";
import "../style/scss/_instruction.scss";
import vtype from "../assets/images/vtype.png";
import book from "../assets/images/book.png";
import exit_number from "../assets/images/exit_number.png";
import exit from "../assets/images/exit.png";
import { PlayStoreBtn } from "./play_store_btn.jsx";

function Instruction() {
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite looping
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    arrows: false, // Show next/prev arrows
  };
  const items = [
    {
      id: 1,
      title: "Select vehicle type",
      content: vtype,
    },
    {
      id: 2,
      title: "Enter vehicle number and book",
      content: book,
    },
    {
      id: 3,
      title: "Search vehicle to exit",
      content: exit_number,
    },
    {
      id: 4,
      title: "Collect payment & Exit",
      content: exit,
    },
  ];
  return (
    <div className="instruction-container">
      <div className="text-section">
        <h1>The smarter way to manage parking</h1>
        <p>
        Elevate your business with ezLocus, the ultimate smart app designed to streamline and enhance your parking management.
        Discover the benefits of smart parking solutions and stay ahead in the competitive market with ezLocus.
        </p>
        {/* <button className="button-primary">How it works</button> */}
        <PlayStoreBtn/>
        {/* <a href="#" className="learn-link">
          Learn how to reserve parking
        </a> */}
      </div>
      <div className="instructions">
        {items.map((item) => (
          <div key={item.id}>
            <InstructionCard
              step={item.id}
              title={item.title}
              content={item.content}
            />
          </div>
        ))}
      </div>
      <div className="slideInstruction">
        <InstructionSlide />
      </div>
    </div>
  );
}

export default Instruction;
