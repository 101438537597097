import "../style/scss/_about_us.scss"; 
import Seo from "../seo";
import HomeSVG from "../assets/svg/Home.svg";
import AccountForm from "../component/account_form";

const Account = () => {
  return (
    <>
      <Seo
        title="Contact Us | ezLocus"
        description="Welcome to ezLocus, your ultimate solution for hassle-free parking management. At ezLocus, we believe in simplifying the parking experience for both businesses and users. Our innovative platform is designed to maximize efficiency, improve customer experience, and empower businesses to make informed decisions."
        keywords="Contact Us, About Parking Booking, User Agreement, ezLocus Terms, Service Use, Payment, Privacy Policy"
        image="%PUBLIC_URL%/images/logo.png"
        url="https://ezlocus.com/account"
      />
      <section id="about-us" className="about-us-section">
        <div className="container" style={{ maxWidth: "1500px" }}>
          <div className="about-us-content">
            <div className="about-us-image">
              <img src={HomeSVG} alt="About Us Image" />
            </div>
            <AccountForm />
          </div>
        </div>
      </section>
    </>
  );
};
export default Account;
