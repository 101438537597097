import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { FaPhone } from "react-icons/fa6";
import logo from '../assets/images/logo.png';
import '../style/scss/_header.scss';
import FacebookPixel from '../analytics/fb_pixel';


const Header = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };

  return (
    <header className="header">
        <FacebookPixel />
      <div className="header-container">
        <NavLink to="/" className="header-logo" onClick={() => setIsMobile(false)}>
          <img src={logo} alt="Company Logo" />
        </NavLink>
        <nav className="navbar">
          <ul className={`navbar-menu ${isMobile ? 'navbar-mobile' : ''}`}>
            <li>
              <NavLink to="/about-us" activeclassname="active" onClick={() => setIsMobile(false)}>About Us</NavLink>
            </li>
            <li>
              <NavLink to="/contact-us" activeclassname="active" onClick={() => setIsMobile(false)}>Contact Us</NavLink>
            </li>
            <li>
              <NavLink to="/pricing" activeclassname="active" onClick={() => setIsMobile(false)}>Pricing</NavLink>
            </li>
            <li>
              <span><FaPhone/> +91-9540621919</span>
            </li>
          </ul>
          <button className="hamburger" onClick={handleToggle}>
            {isMobile ? <FaTimes /> : <FaBars />}
          </button>
        </nav>
      </div>
    </header>
  );
};

export default Header;
