import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./views/Layout";
import Index from "./pages/Index";
import Policy from "./pages/policy";
import AboutUs from "./pages/about_us";
import ContactUs from "./pages/contact_us";
import Pricing from "./pages/pricing";
import GetStart from "./pages/get_start";
import Tnc from "./pages/tnc";
import Account from "./pages/account";
import NotFound from './pages/404';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Index />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/pricing" element={<Pricing />} />
          {/* <Route path="/get_start" element={<GetStart />} /> */}
          <Route path="/get_start/:duration/:plan" element={<GetStart />} />
          <Route path="/account" element={<Account />} />
          <Route path="/terms-and-conditions" element={<Tnc />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
