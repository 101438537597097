import Home from "../component/Home";
import KeyBenefit from "../component/KeyBenefit";
import Features from "../component/Features";
import ContactForm from "../component/Contact";
import ContactUs from "../pages/contact_us";
import InfoSection from "../component/InfoSection";
import Instruction from "../component/Instruction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Seo from "../seo";

const Index = () => {
  return (
    <>
      <Home />
      <KeyBenefit />
      <InfoSection />
      <Instruction />
      {/* <Features /> */}
      <div style={{marginTop: "50px"}}></div>
      <ContactUs />
      <Seo
        title="Home | ezLocus"
        description="Welcome to ezLocus, your ultimate solution for hassle-free parking management. At ezLocus, we believe in simplifying the parking experience for both businesses and users. Our innovative platform is designed to maximize efficiency, improve customer experience, and empower businesses to make informed decisions."
        keywords="About Us, About Parking Booking, User Agreement, ezLocus Terms, Service Use, Payment, Privacy Policy"
        image="%PUBLIC_URL%/images/logo.png"
        url="https://ezlocus.com/"
      />
    </>
  );
};
export default Index;
