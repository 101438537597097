import React from "react";
import "../style/scss/_successPage.scss";

const SuccessPage = () => {
  const steps = [
    <>
      Go to the{' '}
      <a href="https://merchant.ezlocus.com" style={styles.link}>
        <strong>merchant</strong>
      </a>{' '}
      portal and log in.
    </>,
    <>
      Open{' '}
        <strong>settings</strong>{' '}
        from the <strong>profile</strong>{' '}<span style={styles.icon}>👤</span> menu.
    </>,
    <>
        <strong>Add parking</strong>{' '}
        information.
    </>,
  ];

  return (
    <div className="success-container">
      <div className="success-content">
        <div className="icon-checkmark">
          <i className="fas fa-check-circle"></i>
        </div>
        <h1 className="success-title">Registration Successful!</h1>
        <p className="success-message">
          Thank you for choosing ezLocus to manage your parking solutions. 
          {/* Your
          payment has been successfully processed, and your subscription is now
          active. */}
        </p>
        <p className="success-message" style={{textAlign: "start"}}>
          You can now access the Merchant Portal to customize and complete your
          profile. This will help you optimize your parking operations and make
          the most of ezLocus powerful features.
        </p>
        <p style={styles.message}>
          <strong>Follow the steps below to get started:</strong>
        </p>
        <ol style={styles.stepsList}>
          {steps.map((step, index) => (
            <li key={index} style={styles.stepItem}>
              {step}
            </li>
          ))}
        </ol>
        {/* <a href="https://merchant.ezlocus.com" className="merchant-portal-link">
          Login to the Merchant Portal
        </a> */}
        <div className="success-footer" style={{textAlign: "start"}}>
          <p>
            If you have any questions or need assistance, please don’t hesitate
            to reach out to our <a href="/contact-us"> support</a> team.
          </p>
          <p>
            Thank you for your trust in ezLocus. We look forward to helping you
            enhance your parking management.
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: '20px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    maxWidth: '500px',
    margin: 'auto',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontSize: '24px',
    color: '#4caf50',
    marginBottom: '10px',
  },
  message: {
    fontSize: '16px',
    color: '#333',
    marginBottom: '15px',
    textAlign: 'start',
  },
  stepsList: {
    listStyleType: 'decimal',
    paddingLeft: '20px',
    textAlign: 'start'
  },
  stepItem: {
    fontSize: '14px',
    marginBottom: '10px',
    color: '#555',
  },
  link: {
    textDecoration: 'none',
    color: '#1976d2',
  },
  icon: {
    marginRight: '4px',
  },
};

export default SuccessPage;
