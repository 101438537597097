import { GooglePlayButton } from "react-mobile-app-button";
    
    export const PlayStoreBtn = () => {
      const APKUrl = "https://play.google.com/store/apps/details?id=com.techcamin.locusaggregator";
    
      return (
        <div>
          <GooglePlayButton
            url={APKUrl}
            height={60}
            width={200}
            theme={"dark"}
            className={"custom-style"}
          />
        </div>
      );
   };