import React, { useEffect } from 'react';

const FacebookPixel = () => {
  useEffect(() => {
    // Initialize fbq function if it doesn't already exist
    if (!window.fbq) {
      const fbq = function () {
        fbq.callMethod ? fbq.callMethod.apply(fbq, arguments) : fbq.queue.push(arguments);
      };
      if (!window._fbq) window._fbq = fbq;
      fbq.push = fbq;
      fbq.loaded = true;
      fbq.version = '2.0';
      fbq.queue = [];
      const t = document.createElement('script');
      t.async = true;
      t.src = 'https://connect.facebook.net/en_US/fbevents.js';
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(t, s);
      window.fbq = fbq; // Assign fbq to the window object
      window.fbq('init', '1185622576085565');
      window.fbq('track', 'PageView');
    }
  }, []);

  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src="https://www.facebook.com/tr?id=1185622576085565&ev=PageView&noscript=1"
        alt="Facebook Pixel"
      />
    </noscript>
  );
};

export default FacebookPixel;
