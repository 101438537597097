import React from "react";
import Slider from "react-slick";
import "../style/scss/SliderComponent.scss";
import vtype from "../assets/images/vtype.png";
import book from "../assets/images/book.png";
import exit_number from "../assets/images/exit_number.png";
import exit from "../assets/images/exit.png";

const items = [
  {
    id: 1,
    title: "Select vehicle type",
    content: vtype,
  },
  {
    id: 2,
    title: "Enter vehicle number and book",
    content: book,
  },
  {
    id: 3,
    title: "Search vehicle to exit",
    content: exit_number,
  },
  {
    id: 4,
    title: "Collect payment & Exit",
    content: exit,
  },
];

const SliderComponent = () => {
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite looping
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at once
    arrows: false, // Show next/prev arrows
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {items.map((item) => (
          <div key={item.id} className="slide">
            <img
              src={item.content}
              alt={`Slide ${item.id}`}
              className="slide-image"
            />
            <h2 className="slide-title">{item.id}</h2>
            <p className="slide-text">{item.title}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderComponent;
