import React, { useRef, useEffect }  from "react";
import HomeSVG from "../assets/svg/Home.svg";
import Promo from "../assets/videos/promo.mp4";
import "../style/scss/_home.scss";
import { useNavigate } from "react-router-dom";
import Seo from "../seo";

const Home = () => {
  const navigate = useNavigate();

  const scrollToContactUs = () => {
    navigate("/"); // Ensure you are on the homepage first
    setTimeout(() => {
      document
        .getElementById("contact-us")
        .scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  /// video controlls
  // const videoRef = useRef(null);

  // useEffect(() => {
  //   const videoElement = videoRef.current;

  //   const handleEnded = () => {
  //     videoElement.currentTime = 0;
  //     videoElement.load(); // Reloads the video so the poster image appears
  //     videoElement.pause();
  //   };

  //   videoElement.addEventListener('ended', handleEnded);

  //   return () => {
  //     videoElement.removeEventListener('ended', handleEnded);
  //   };
  // }, []);

  return (
    <div className="section">
      <Seo
        title="Home | ezLocus"
        description="Welcome to ezLocus, your ultimate solution for hassle-free parking management. At ezLocus, we believe in simplifying the parking experience for both businesses and users. Our innovative platform is designed to maximize efficiency, improve customer experience, and empower businesses to make informed decisions."
        keywords="About Us, About Parking Booking, User Agreement, ezLocus Terms, Service Use, Payment, Privacy Policy"
        image="%PUBLIC_URL%/images/logo.png"
        url="https://ezlocus.com/"
      />
      <div className="container-fluid">
        <div className="row home-container gx-0">
          <div className="col-sm-12 col-md-6 order-md-1 order-2 custom-bg m-auto home-h1-heading">
            <div className="wrap">
              <h1 className="home-title custom-text-color">
                <span className="custom-subtext-color">Digitize Parking</span>{" "}
                of any scale and{" "}
                <span className="custom-subtext-color">Enhance Revenue</span>{" "}
                potential
              </h1>
              <div className="home-text custom-text-color">
              Manage parking effortlessly with our easy-to-use cloud based platform. Simplify reservations, track real-time parking, and seamlessly integrate with your existing systems to optimize space and improve customer satisfaction.
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 order-md-2 order-1 home-image">
            <div className="heroWrapper" style={{ position: 'relative', paddingTop: '56.25%' /* 16:9 aspect ratio */ }}>
             
            <iframe
              src="https://www.youtube.com/embed/xtuUkyke4y4?autoplay=1&rel=0&controls=1&showinfo=0&modestbranding=1&mute=1"
              title="YouTube Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none'
              }}
            />
                {/* <video 
                  ref={videoRef} 
                  width="100%" 
                  controls 
                  autoPlay 
                  muted  
                  // loop
                  poster={HomeSVG}>
                  <source src={Promo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video> */}
              {/* https://www.youtube.com/watch?v=xtuUkyke4y4 */}
              {/* <img
                className="preview img-fluid"
                src={HomeSVG}
                placeholder="blur"
                alt="Team"
              /> */}
            </div>
          </div>
        </div>

        <div className="btns">
          <button
            aria-hidden="true"
            className="button mt-3"
            onClick={scrollToContactUs}
          >
            Request a Demo
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
