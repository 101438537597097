import React from "react";
import { useNavigate } from "react-router-dom";
import "../style/scss/_404.scss"; // Assuming you want to style it using SCSS

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/"); // Redirects the user to the homepage
  };

  return (
    <div className="not-found-container">
      <h1 className="error-code">404</h1>
      <h2 className="error-message">Oops! Page Not Found</h2>
      <p className="error-description">
        The page you're looking for doesn't exist or has been moved.
      </p>
      <button className="go-home-button" onClick={handleGoHome}>
        Go to Homepage
      </button>
    </div>
  );
};

export default NotFound;
