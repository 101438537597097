import "../style/scss/_about_us.scss"; // Import your SCSS file
import Seo from "../seo";
import contactusSVG from "../assets/svg/contact_us.svg";
import ContactForm from "../component/Contact";

const AboutUs = () => {
  return (
    <>
      <Seo
        title="Contact Us | ezLocus"
        description="Welcome to ezLocus, your ultimate solution for hassle-free parking management. At ezLocus, we believe in simplifying the parking experience for both businesses and users. Our innovative platform is designed to maximize efficiency, improve customer experience, and empower businesses to make informed decisions."
        keywords="Contact Us, About Parking Booking, User Agreement, ezLocus Terms, Service Use, Payment, Privacy Policy"
        image="%PUBLIC_URL%/images/logo.png"
        url="https://ezlocus.com/contact-us"
      />
      <section id="about-us" className="about-us-section">
        <div
          className="container"
        >
          <div className="about-us-content">
            <div className="about-us-image">
              <img src={contactusSVG} alt="About Us Image" />
            </div>
            <ContactForm />
          </div>
        </div>
      </section>
    </>
  );
};
export default AboutUs;
